import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader";
import BaseLayout from "../components/global/BaseLayout";


function OurVideos() {
    const { rpdata } = useContext(GlobalDataContext);

    return (
        <BaseLayout PageName="Our Videos">
            <div className="md:max-w-full w-full">
                <TransparentHeader
                    headertitle="Our Videos"
                    Subheader="Our Videos"
                    bgimg={`${rpdata?.stock?.[0]}`}
                />
            </div>
            <div className="w-4/5 mx-auto py-[100px]">
                <h1 className="text-center pb-10">Our Videos</h1>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
                    {
                        rpdata?.ytGallery?.linkCanalYT ?
                            rpdata?.ytGallery?.links.map((item, index) => {
                                return (
                                    <iframe
                                        key={index}
                                        width="100%"
                                        height="200"
                                        src={`https://www.youtube.com/embed/${item}?controls=0`}
                                        title="Video Player Interscope Media"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen
                                        className="rounded-2xl shadow-xl"
                                    />
                                )
                            })
                            : null
                    }
                </div>
            </div>
        </BaseLayout>
    );
}

export default OurVideos;